import React, { useEffect, useState, useRef } from "react"
import Select from "react-select/async"
import Swal from "sweetalert2"
import { useBaseContext } from "context/base"
import InvalidWarning from "component/invalid/warning"
import debounce from "lodash.debounce"

export default function PathwayFormAdd({
  setIsAdd,
  classId,
  userId,
  setEventChange,
  pathwayType,
  modalEvent,
}) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()

  /* ========================================= Constants ======================================== */
  const beforeHelp =
    "Mata Pelajaran tersedia/dapat diakses setelah siswa menyelesaikan beberapa Mata Pelajaran yang ditentukan oleh instruktur."
  const afterHelp =
    "Rekomendasi beberapa Mata Pelajaran yang dapat diikuti siswa setelah menyelesaikan Mata Pelajaran ini."

  /* ======================================= Local States ======================================= */
  const [classOptions, setClassOptions] = useState([
    {
      value: "",
      label: "Data Mata Pelajaran tidak ditemukan.",
      isDisabled: true,
    },
  ])
  const [detailClasses, setDetailClasses] = useState([])
  const [isDetailClassesEmpty, setIsDetailClassesEmpty] = useState(false)

  /* =========================================== Refs =========================================== */
  const detailClassesRef = useRef()

  /* ========================================= Functions ======================================== */
  async function getClasses() {
    const response = await getRequest(
      `classes?role_type=administrator&limit=10&offset=0`
    )
    if (response) {
      initClasses(response.data)
    }
  }

  const getOptionsClasses = debounce(async (inputValue, callback) => {
    try {
      const response = await getRequest(
        `classes?role_type=administrator&limit=10&offset=0${
          inputValue ? `&search=${inputValue}` : ""
        }`
      )

      if (response && response?.data) {
        detailClassesRef.current.focus()
        const classList = response?.data
        const options = classList
          .filter((classItem) => classItem.id !== classId)
          .map((classItem) => ({
            value: classItem.id,
            label: classItem.title,
          }))

        setClassOptions(options)
        callback(options)
        return options
      }
    } catch (error) {
      detailClassesRef.current.focus()
      console.error("Failed to fetch class options:", error)
    }
    return []
  }, 600)

  function initClasses(classList) {
    let options = []
    classList.forEach((classItem) => {
      if (classItem.id !== classId) {
        options.push({
          value: classItem.id,
          label: classItem.title,
        })
      }
    })
    setClassOptions(options)
  }

  function handleChangeDetailClasses(classes) {
    if (classes) {
      setDetailClasses(classes)
      setIsDetailClassesEmpty(false)
    } else {
      setDetailClasses([])
    }
  }

  function convertDetailClass() {
    if (detailClasses) {
      const newDetails = detailClasses.map((detail) => {
        return detail.value
      })
      return newDetails
    } else {
      return []
    }
  }

  function formValidation() {
    const newDetails = convertDetailClass()
    if (newDetails.length !== 0) {
      setIsDetailClassesEmpty(false)
      submitForm(userId, classId, pathwayType, newDetails)
    } else {
      if (newDetails.length === 0) {
        setIsDetailClassesEmpty(true)
        detailClassesRef.current.focus()
      } else {
        setIsDetailClassesEmpty(false)
      }
    }
  }

  async function submitForm(user_id, class_id, type, detail) {
    let form = new FormData()
    form.append("user_id", user_id)
    form.append("class_id", class_id)
    form.append("type", type)
    form.append("detail", JSON.stringify(detail))

    const response = await postRequest("class-pathway", form)
    if (response) {
      Swal.fire({
        title: "Berhasil menambahkan pathway!",
        icon: "success",
      }).then(() => {
        setIsAdd(false)
        setEventChange("create")
      })
    } else {
      console.error("ERROR", response)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getClasses()
  }, [])

  useEffect(() => {
    if (modalEvent === null) {
      setIsDetailClassesEmpty(false)
      setDetailClasses(null)
    }
  }, [modalEvent])

  /* ========================================== Output ========================================== */
  return (
    <form>
      <h5 className="mb-3">
        <span>Type pathway {pathwayType}</span>
        <i
          className="fas fa-question-circle ml-1"
          title={pathwayType === "after" ? afterHelp : beforeHelp}
        />
      </h5>
      <div className="form-group row ">
        <label className="col-form-label col-sm-3">
          Mata Pelajaran {pathwayType}
        </label>
        <div className="col-sm-9">
          <Select
            ref={detailClassesRef}
            isMulti
            placeholder="Daftar Mata Pelajaran"
            defaultOptions={classOptions}
            value={detailClasses}
            loadOptions={getOptionsClasses}
            onChange={(opt) => handleChangeDetailClasses(opt)}
          />
          {isDetailClassesEmpty ? (
            <InvalidWarning text="Daftar Mata Pelajaran pathway tidak boleh kosong." />
          ) : null}
        </div>
      </div>
      <div className="form-group row mb-0">
        <div className="offset-md-9 col-12 col-sm-6 col-md-3">
          <button
            onClick={formValidation}
            type="button"
            className="btn btn-sm btn-success w-100"
          >
            Simpan
          </button>
        </div>
      </div>
    </form>
  )
}
