import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useBaseContext } from 'context/base'
import LoadingSpinner from 'component/loading'
import NotFound from 'component/not-found'
import Section from './section'
import useOverviewContentContext, { OverviewContentContextWrapper } from './context/overviewContent.context'
import BaseModalSelectedStudent from 'component/modal/BaseModalSelectedStudent'

function OverviewContents({ kelasId, access }) {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const params = useParams()

  /* ====================================== Consume Context ===================================== */
  const { getRequest } = useBaseContext()
  const overviewContentContext = useOverviewContentContext()

  /* ======================================= Local States ======================================= */
  const [sections, setSections] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  /* ========================================= Functions ======================================== */
  async function getSections() {
    const response = await getRequest(`sections?class_id=${kelasId}`)
    setIsLoading(false)
    if (response) {
      setSections(response.data)
    }
  }

  const onSaveSpesificStudentHandler = (onSuccessHandler) => {
    overviewContentContext.handler.onSaveSpesificStudentHandler({
      classId: params?.kelasId,
      contentId: overviewContentContext.data.selectedContent?.id,
      onSuccessHandler: () => {
        getSections()
        if (onSuccessHandler && typeof onSuccessHandler === 'function') {
          onSuccessHandler()
        }
      }
    })
  }

  function addSection(event) {
    event.preventDefault()
    history.push(`/kelas/${kelasId}/add-section`, { kelasId })
  }

  function addContent(event, contentTypeId) {
    event.preventDefault()
    switch (contentTypeId) {
      case 1:
        history.push(`/kelas/${kelasId}/add-content-text`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 2:
        history.push(`/kelas/${kelasId}/add-content-audio`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 3:
        history.push(`/kelas/${kelasId}/add-content-video`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 4:
        history.push(`/kelas/${kelasId}/add-content-document`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 5:
        history.push(`/kelas/${kelasId}/add-content-iframe`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 6:
        history.push(`/kelas/${kelasId}/add-content-quiz`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 7:
        history.push(`/kelas/${kelasId}/add-content-survey`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 8:
        history.push(`/kelas/${kelasId}/add-content-assignment`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 9:
        history.push(`/kelas/${kelasId}/add-content-graphor`, {
          sectionList: sections,
          kelasId,
        })
        break
      case 10:
        history.push(`/kelas/${kelasId}/add-content-whiteboard`, {
          sectionList: sections,
          kelasId,
        })
        break
      default:
        break
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getSections()
  }, [])

  /* ========================================== Output ========================================== */
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card user-profile-list  ">
            <div className="card-header d-flex justify-content-between">
              <div className="btn-group mr-2">
                <button
                  className={`btn btn-primary dropdown-toggle ${
                    access ? 'btn-forbidden' : ''
                  }`}
                  type="button"
                  disabled={access}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="feather icon-plus-circle mr-1" />
                  <span>Tambah Materi | Tugas | Quiz | Whiteboard</span>
                </button>
                <div className="dropdown-menu">
                  <a
                    onClick={(event) => addSection(event)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-hashtag mr-1" />
                    <span>Section</span>
                  </a>
                  <div className="dropdown-divider" />
                  <a
                    onClick={(event) => addContent(event, 1)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-file-alt mr-1" />
                    <span>Teks</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 2)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-volume-up mr-1" />
                    <span>Audio</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 3)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-play-circle mr-1" />
                    <span>Video</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 4)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-file mr-1" />
                    <span>Dokumen | Presentasi</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 5)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-code mr-1" />
                    <span>iFrame</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 6)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-question-circle mr-1" />
                    <span>Quiz</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 7)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-check-double mr-1" />
                    <span>Survey</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 8)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-pen-alt mr-1" />
                    <span>Tugas</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 9)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-object-group mr-1" />
                    <span>Graphic Organizer</span>
                  </a>
                  <a
                    onClick={(event) => addContent(event, 10)}
                    className="dropdown-item"
                    type="button"
                  >
                    <i className="fas fa-chalkboard mr-1" />
                    <span>Whiteboard</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ul className="list-unstyled">
                {isLoading ? (
                  <LoadingSpinner />
                ) : sections.length !== 0 ? (
                  sections.map((section, index) => (
                    <Section
                      key={section.id}
                      item={section}
                      index={index}
                      sections={sections}
                      access={access}
                    />
                  ))
                ) : (
                  <div className="row">
                    <NotFound
                      icon="fas fa-file"
                      text="Materi belum ada"
                    />
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <BaseModalSelectedStudent
        show={overviewContentContext.data.showModalSelectedStudent}
        onCloseHandler={() => overviewContentContext.handler.toggleShowModalSelectedStudentHandler(false)}
        selectedStudents={overviewContentContext.data.selectedStudents}
        onAddOrRemoveStudent={overviewContentContext.set.setSelectedStudents}
        onSaveHandler={onSaveSpesificStudentHandler}
        subMapelId={params?.kelasId}
        title={overviewContentContext.data.selectedContent?.renderTitle}
        originalSelectedStudents={overviewContentContext.data.originalSelectedStudents}
        isWithCheckSavedOrNot={true}
        listSelectedStudentsProps={{
          listStudentsActions: ['rollback', 'clear-all', 'check-all']
        }}
      />
    </>
  )
}


export default function OverviewContentsWrapper({ kelasId, access }) {
  return <OverviewContentContextWrapper>
    <OverviewContents kelasId={kelasId} access={access} />
  </OverviewContentContextWrapper>
}