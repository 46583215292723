import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'
import { Submit } from 'component/button'
import { getBase64 } from 'util/base64'

export default function BasicProfile() {
	/* ========================================== Helpers ========================================== */
  const { register, handleSubmit } = useForm()

	/* ====================================== Consume Context ====================================== */
  const { getRequest, putRequest } = useBaseContext()

	/* ======================================== Local States ======================================= */
  const [getData, setGetData]                                               = useState(null)
  const [image, setImage]                                                   = useState({ name: '', data: '' })
  const [logoMinimized, setLogoMinimized]                                   = useState({ name: '', data: '' })
  const [logoColored, setLogoColored]                                       = useState({ name: '', data: '' })
  const [logoColoredMinimized, setLogoColoredMinimized]                     = useState({ name: '', data: '' })
  const [headerPeriod, setHeaderPeriod]                                     = useState({ name: '', data: '' })
  const [previewLogo, setPreviewLogo]                                       = useState('')
  const [previewLogoMinimized, setPreviewLogoMinimized]                     = useState('')
  const [previewLogoColored, setPreviewLogoColored]                         = useState('')
  const [previewLogoColoredMinimize, setPreviewLogoColoredMinimized]        = useState('')
  const [previewHeader, setPreviewHeader]                                   = useState('')
  const [isLogoChanged, setIsLogoChanged]                                   = useState(false)
  const [isLogoMinimizedChanged, setIsLogoMinimizedChanged]                 = useState(false)
  const [isLogoColoredChanged, setIsLogoColoredChanged]                     = useState(false)
  const [isLogoColoredMinimizedChanged, setIsLogoColoredMinimizedChanged]   = useState(false)
  const [isHeaderChanged, setIsHeaderChanged]                               = useState(false)

	/* ========================================= Functions ========================================= */
  async function getProfile() {
    let response = await getRequest('myprofile')
    if (response) {
      const responseData = response?.data;

      setGetData(responseData)

      if(responseData?.header_period){
        setPreviewHeader(responseData?.header_period)
      }

      if(responseData?.logo){
        setPreviewLogo(responseData?.logo)
      }

      if(responseData?.logo_minimized){
        setPreviewLogoMinimized(responseData?.logo_minimized)
      }

      if(responseData?.logo_colored){
        setPreviewLogoColored(responseData?.logo_colored)
      }

      if(responseData?.logo_colored_minimized){
        setPreviewLogoColoredMinimized(responseData?.logo_colored_minimized)
      }
    }
  }

  function PreviewLogo({ logo }) {
    if (typeof logo === 'string') {
      return (
        <img
          src={`${logo}`}
          className="img-fluid mt-3"
          style={{
            backgroundColor: '#333',
            border: '2px dashed #999',
            maxWidth: '360px',
            maxHeight: '360px',
          }}
          onError={(e) => {
            e.target.src = '/image/bg-header2.png'
          }}
        />
      )
    } else if (typeof logo === 'object') {
      return (
        <img
          src={`data:image/jpeg;base64,${logo.data}`}
          className="img-fluid mt-3"
          style={{
            backgroundColor: '#333',
            border: '2px dashed #999',
            maxWidth: '360px',
            maxHeight: '360px',
          }}
        />
      )
    } else {
      return null
    }
  }

  function imgPlaceholder({ logo, type = "logo" }) {
    const idProfile = JSON.parse(localStorage.getItem("profile"))?.id

    if (typeof logo === "string") {
      const typeSuffix = {
        logo: "",
        logo_minimized: "_minimized",
        logo_colored: "_colored",
        logo_colored_minimized: "_colored_minimized",
      }[type] || "";
      return logo ? `${idProfile}${typeSuffix}.png` : "Pilih logo (jpg/png)";
    } else if (typeof logo === "object") {
      return logo?.name
    } else {
      return ""
    }
  }

  function checkImage(val, type = 'logo') {
    getBase64(val)
      .then((result) => {
        let ii = result.split(',')
        if (type === 'logo') {
          setIsLogoChanged(true)
          setImage({ name: val?.name, data: ii[1] })
          setPreviewLogo({ name: val?.name, data: ii[1] })
        } else if (type === 'logo_minimized'){
          setIsLogoMinimizedChanged(true)
          setLogoMinimized({name: val?.name, data: ii[1]})
          setPreviewLogoMinimized({name: val?.name, data: ii[1]})
        } else if (type === 'logo_colored') {
          setIsLogoColoredChanged(true)
          setLogoColored({name: val?.name, data: ii[1]})
          setPreviewLogoColored({name: val?.name, data: ii[1]})
        } else if (type === 'logo_colored_minimized') {
          setIsLogoColoredMinimizedChanged(true)
          setLogoColoredMinimized({name: val?.name, data: ii[1]})
          setPreviewLogoColoredMinimized({name: val?.name, data: ii[1]})
        } else {
          setIsHeaderChanged(true)
          setHeaderPeriod({ name: val.name, data: ii[1] })
          setPreviewHeader({ name: val.name, data: ii[1] })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async function onSubmit(data) {
    let form = new FormData()
    form.append('name', data?.name)
    form.append('school_name', data?.school_name)
    form.append('city', data?.city)
    form.append('desc', data?.desc)
    form.append('location', data?.location)
    form.append('website', data?.website)
    form.append('facebook', data?.facebook)
    form.append('twitter', data?.twitter)
    form.append('instagram', data?.instagram)
    
    if (isLogoChanged) {
      form.append('logo', JSON.stringify(image))
    }

    if(isLogoMinimizedChanged) {
      form.append("logo_minimized", JSON.stringify(logoMinimized))
    }

    if(isLogoColoredChanged){
      form.append("logo_colored", JSON.stringify(logoColored))
    }

    if(isLogoColoredMinimizedChanged){
      form.append("logo_colored_minimized", JSON.stringify(logoColoredMinimized))
    }

    if (isHeaderChanged) {
      form.append('header_period', JSON.stringify(headerPeriod))
    }

    const response = await putRequest('profile-update', form)

    if (response) {
      window.notification('Berhasil', 'Profile berhasil diubah', 'success')
    }
  }

	/* ========================================== Effects ========================================== */
  useEffect(() => {
    getProfile()
  }, [])

	/* =========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Profile Setting" linkName="Basic Profile" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Identitas</h5>
              </div>
              <div className="card-body">
                {getData && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                      <label
                        htmlFor="name"
                        className="col-sm-2 col-form-label"
                      >
                        Nama profile
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('name')}
                          defaultValue={getData?.name}
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Nama Profile"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="school_name"
                        className="col-sm-2 col-form-label"
                      >
                        Nama Sekolah
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('school_name')}
                          defaultValue={getData?.school_name}
                          type="text"
                          className="form-control"
                          id="school_name"
                          placeholder="Nama Sekolah"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="city"
                        className="col-sm-2 col-form-label"
                      >
                        Kota
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('city')}
                          defaultValue={getData?.city}
                          type="text"
                          className="form-control"
                          id="city"
                          placeholder="Kota"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="address"
                        className="col-sm-2 col-form-label"
                      >
                        Alamat
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('location')}
                          defaultValue={getData?.location}
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder="Alamat Profile"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="website"
                        className="col-sm-2 col-form-label"
                      >
                        Website
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('website')}
                          defaultValue={getData?.website}
                          type="text"
                          className="form-control"
                          id="website"
                          placeholder="Website URL"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="instagram"
                        className="col-sm-2 col-form-label"
                      >
                        Instagram
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('instagram')}
                          defaultValue={getData?.instagram}
                          type="text"
                          className="form-control"
                          id="instagram"
                          placeholder="Instagram Username"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="facebook"
                        className="col-sm-2 col-form-label"
                      >
                        Facebook
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('facebook')}
                          defaultValue={getData?.facebook}
                          type="text"
                          className="form-control"
                          id="facebook"
                          placeholder="Facebook Username"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="twitter`"
                        className="col-sm-2 col-form-label"
                      >
                        Twitter
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <input
                          {...register('twitter')}
                          defaultValue={getData?.twitter}
                          type="text"
                          className="form-control"
                          id="twitter"
                          placeholder="Twitter Account"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="deskripsi"
                        className="col-sm-2 col-form-label"
                      >
                        Deskripsi
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <textarea
                          {...register('desc')}
                          defaultValue={getData?.desc}
                          type="text"
                          className="form-control"
                          id="deskripsi"
                          placeholder="Deskripsi Profile"
                        />
                      </div>
                    </div>
                    {/* Logo  */}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Logo</label>
                      <div className="col-sm-6 col-xl-3">
                        <div className="custom-file">
                          <input
                            onChange={(val) =>
                              checkImage(val.target.files[0], "logo")
                            }
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                            style={{ overflow: 'hidden' }}
                          >
                            {imgPlaceholder({logo: previewLogo, type: "logo"})}
                          </label>
                        </div>
                      </div>
                      <div className="offset-sm-2 col-sm-10">
                        <PreviewLogo logo={previewLogo} />
                      </div>
                      <div className="col-sm-3 offset-sm-2 mt-2">
                        <div
                          className="alert alert-warning"
                          role="alert"
                        >
                          <strong>*) Ukuran yang dianjurkan: </strong>
                           770 (w) x 220 (h)
                        </div>
                      </div>
                    </div>

                    {/* Logo Minimized */}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Logo Minimized</label>
                      <div className='col-sm-6 col-xl-3'>
                        <div className='custom-file'>
                        <input
                            onChange={(val) =>
                              checkImage(val.target.files[0], "logo_minimized")
                            }
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                            style={{ overflow: 'hidden' }}
                          >
                             {imgPlaceholder({logo: previewLogoMinimized, type: "logo_minimized"})}
                          </label>
                        </div>
                      </div>
                      <div className='offset-sm-2 col-sm-10'>
                        <PreviewLogo logo={previewLogoMinimized} />
                      </div>
                      <div className="col-sm-3 offset-sm-2 mt-2">
                        <div
                          className="alert alert-warning"
                          role="alert"
                        >
                          <strong>*) Ukuran yang dianjurkan: </strong>
                           75 (w) x 75 (h)
                        </div>
                      </div>
                    </div>

                    {/* Logo Berwarna */}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Logo Berwarna</label>
                      <div className='col-sm-6 col-xl-3'>
                        <div className='custom-file'>
                        <input
                            onChange={(val) =>
                              checkImage(val.target.files[0], "logo_colored")
                            }
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                            style={{ overflow: 'hidden' }}
                          >
                            {imgPlaceholder({logo: previewLogoColored, type: "logo_colored"})}
                          </label>
                        </div>
                      </div>
                      <div className='offset-sm-2 col-sm-10'>
                        <PreviewLogo logo={previewLogoColored} />
                      </div>
                      <div className="col-sm-3 offset-sm-2 mt-2">
                        <div
                          className="alert alert-warning"
                          role="alert"
                        >
                          <strong>*) Ukuran yang dianjurkan: </strong>
                           770 (w) x 220 (h)
                        </div>
                      </div>
                    </div>

                     {/* Logo Berwarna Minimized */}
                     <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Logo Berwarna Minimized</label>
                      <div className='col-sm-6 col-xl-3'>
                        <div className='custom-file'>
                        <input
                            onChange={(val) =>
                              checkImage(val.target.files[0], "logo_colored_minimized")
                            }
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                            style={{ overflow: 'hidden' }}
                          >
                            {imgPlaceholder({logo: previewLogoColoredMinimize, type: "logo_colored_minimized"})}
                          </label>
                        </div>
                      </div>
                      <div className='offset-sm-2 col-sm-10'>
                        <PreviewLogo logo={previewLogoColoredMinimize} />
                      </div>
                      <div className="col-sm-3 offset-sm-2 mt-2">
                        <div
                          className="alert alert-warning"
                          role="alert"
                        >
                          <strong>*) Ukuran yang dianjurkan: </strong>
                           75 (w) x 75 (h)
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Kop Raport Period
                      </label>
                      <div className="col-sm-6 col-xl-3">
                        <div className="custom-file">
                          <input
                            onChange={(val) =>
                              checkImage(val.target.files[0], 'header_period')
                            }
                            type="file"
                            className="custom-file-input"
                            id="validatedCustomFile"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="validatedCustomFile"
                            style={{ overflow: 'hidden' }}
                          >
                            {imgPlaceholder(previewHeader)}
                          </label>
                        </div>
                      </div>
                      <div className="offset-sm-2 col-sm-10">
                        <PreviewLogo logo={previewHeader} />
                      </div>
                      <div className="col-sm-3 offset-sm-2 mt-2">
                        <div
                          className="alert alert-warning"
                          role="alert"
                        >
                          <strong>*) Ukuran yang dianjurkan: </strong> 2480
                          (w) x 900 (h)
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-2 col-form-label"
                      ></label>
                      <div className="col-sm-3">
                        <button
                          type="submit"
                          className="btn btn-success has-ripple mr-2"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Pengumuman</h5>
              </div>
              <div className="card-body">
                <ul
                  className="nav nav-tabs mb-3"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active text-uppercase"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Internal
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-uppercase"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      External
                    </a>
                  </li>
                </ul>
                <div
                  className="tab-content"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-2 col-form-label"
                        >
                          Isi pengumuman untuk internal
                        </label>
                        <div className="col-sm-4">
                          <textarea
                            type="email"
                            className="form-control"
                            id="inputEmail3"
                            placeholder="Isi pengumuman"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-2 col-form-label"
                        ></label>
                        <div className="col-sm-4">
                          <Submit
                            onClick={() => {}}
                            text="Simpan"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <form>
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-2 col-form-label"
                        >
                          Isi pengumuman untuk external
                        </label>
                        <div className="col-sm-4">
                          <textarea
                            type="email"
                            className="form-control"
                            id="inputEmail3"
                            placeholder="Isi pengumuman"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-2 col-form-label"
                        ></label>
                        <div className="col-sm-4">
                          <Submit
                            onClick={() => {}}
                            text="Simpan"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}
