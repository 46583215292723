import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { host } from 'util/host'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import Swal from 'sweetalert2'

/* ========================================== Language ========================================== */
import id from 'lang/id-ID.json'
import en from 'lang/en-US.json'
import es from 'lang/es-ES.json'

const resourceLang = {
  id,
  en,
  es,
}

/* ======================================= Export Provider ====================================== */
export const BaseContext = createContext()

/* ========================================= Export Hook ======================================== */
export function useBaseContext() {
  return useContext(BaseContext)
}

/* ========================================== Provider ========================================== */
const BaseContextWrapper = ({ children }) => {
  const [login, setLogin]               = useState(false)
  const [profile, setProfile]           = useState(() => {
    const profileStringify = localStorage.getItem('profile')
    if (profileStringify) {
      const profile = JSON.parse(profileStringify)

      if (profile?.logo_minimized) {
        return profile
      }

      return null
    }

    return null
  })
  const [menus, setMenus]               = useState([])
  const [languageCode, setLanguageCode] = useState('')
  const [language, setLanguage]         = useState('')

  /* ========================================== Helpers ========================================= */
  const location = useLocation()
  const consumeQuery = () => new URLSearchParams(location.search)
  const query = consumeQuery()

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    const initializeLanguage = async () => {
      let l = localStorage.getItem('language')
      if (l) {
        let ll = l.split('-')
        setLanguage(ll[0])
        setLanguageCode(l)
      } else {
        setLanguage('id')
        setLanguageCode('id-ID')
      }
    }

    initializeLanguage()
  }, [])

  /* ========================================= Functions ======================================== */
  const changeLanguage = (code) => {
    localStorage.setItem('language', code)
    let ll = code.split('-')
    setLanguage(ll[0])
    setLanguageCode(code)
  }

  const getLang = (obj) => {
    if (language !== '') {
      return resourceLang[language][obj]
    }
    return ''
  }

  const setValue = (key, value) => {
    switch (key) {
      case 'login':
        setLogin(value)
        break
      case 'menus':
        setMenus(value)
        break
      default:
        break
    }
  }

  const getRequest = async (url, notif = true, axiosOptions, isGenerate) => {
    let token = query.get('token') || localStorage.getItem('token')
    
    let header = {}
    if (token) {
      header.token = token
    }

    try {
      const response = await axios({
        method: 'GET',
        url: ((isGenerate && host == 'https://api.pintaredu.id/' ) ? 'http://10.10.12.3:3040/' : host) + url,
        headers: header,
        ...axiosOptions,
      })
      return response.data
    } catch (error) {
      if (error.response) {
        var status = error.response.status
        if (status === 444) {
          if (window.refreshingToken) {
            return null
          } else {
            window.refreshingToken = true
            return refreshToken().then(async (result) => {
              if (result) {
                let res = await getRequest(url)
                setTimeout(() => {
                  window.refreshingToken = false
                }, 5000)
                return res
              } else {
                localStorage.removeItem('expired')
                setLogin(false)
              }
            })
          }
        } else if (status === 445 || status === 403) {
          console.error('Data', error.response.data)
          console.error('STATUS', error.response.status)
          console.error('HEADER', error.response.headers)
          localStorage.removeItem('expired')
          setLogin(false)
        } else if (status === 404) {
          console.error('Response', error)
          if (notif) {
            if (window.$.notify) {
              window.notification(
                'Maaf,',
                'url/file yang anda minta tidak tersedia',
                'warning',
              )
            }
          }
        } else {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Data', error.response.data)
            console.error('STATUS', error.response.status)
            console.error('HEADER', error.response.headers)
            if (notif) {
              if (window.$.notify) {
                if (
                  error.response.data.message &&
                  typeof error.response.data.message === 'string'
                ) {
                  window.notification('', error.response.data.message, 'danger')
                } else {
                  window.notification(
                    'Terjadi kesalahan',
                    ', coba beberapa saat lagi',
                    'danger',
                  )
                }
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error('REQUEST', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message)
          }

          console.error(error.config)
        }
      } else {
        if (window.$.notify) {
          window.notification('Koneksi', 'terputus dengan server', 'danger')
        }
      }
      return null
    }
  }

  const postRequest = async (
    url,
    form,
    notif = false,
    title = 'berhasil',
    message = 'berhasil',
    type = 'success',
  ) => {
    try {
      let token = query.get('token') || localStorage.getItem('token')

      let header = {}
      if (token) {
        header.token = token
      }
      const response = await axios({
        method: 'POST',
        url: host + url,
        headers: header,
        data: form,
        onUploadProgress: (progressEvent) => {
          var progress = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100),
          )
          if (window) window.loadingUpload(progress)
        },
      })
      if (notif) {
        if (window.$.notify) {
          window.notification(title, message, type)
        }
      }
      return response.data
    } catch (error) {
      if (error.response) {
        var status = error.response.status
        if (status === 444) {
          if (window.refreshingToken) {
            return null
          } else {
            window.refreshingToken = true
            return refreshToken().then(async (result) => {
              if (result) {
                let res = await postRequest(
                  url,
                  form,
                  notif,
                  title,
                  message,
                  type,
                )
                setTimeout(() => {
                  window.refreshingToken = false
                }, 5000)
                return res
              } else {
                localStorage.removeItem('expired')
                setLogin(false)
              }
            })
          }
        } else if (status === 445 || status === 403) {
          localStorage.removeItem('expired')
          setLogin(false)
        } else if (status === 409) {
          console.error('Error', error.response)
          Swal.fire('Maaf', error.response.data.message, 'warning')
        } else if (status === 401) {
          console.error('Response', error)
          if (window.$.notify) {
            const message = error.response.data.message
              ? error.response.data.message
              : 'Terjadi Kesalahan, coba beberapa saat lagi'
            window.notification('', message, 'warning')
          }
        } else if (status === 418) {
        } else {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Data', error.response.data)
            console.error('STATUS', error.response.status)
            console.error('HEADER', error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error('REQUEST', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message)
          }

          console.error(error.config)

          if (error.response.data.message === 'username sudah digunakan') {
            return error.response.data
          } else if (error.response.data.message === 'nim sudah digunakan') {
            return error.response.data
          } else if (error.response.data.message === 'password tidak cocok') {
            return error.response.data
          } else if (
            error.response.data.message === 'Kode quiz sudah digunakan'
          ) {
            return error.response.data
          } else if (
            error.response.data.message === 'Code tugas sudah digunakan'
          ) {
            return error.response.data
          } else {
            if (window.$.notify) {
              if (
                error.response.data.message &&
                typeof error.response.data.message === 'string'
              ) {
                window.notification('', error.response.data.message, 'danger')
              } else {
                window.notification(
                  'Terjadi kesalahan',
                  ', coba beberapa saat lagi',
                  'danger',
                )
              }
            }
            return null
          }
        }
      } else {
        if (window.$.notify) {
          window.notification('Koneksi', 'terputus dengan server', 'danger')
        }
      }
    }
  }

  const putRequest = async (
    url,
    form,
    notif = false,
    title = 'berhasil',
    message = 'berhasil',
    type = 'success',
  ) => {
    try {
      let token = localStorage.getItem('token')
      let header = {}
      if (token) {
        header.token = token
      }
      const response = await axios({
        method: 'PUT',
        url: host + url,
        headers: header,
        data: form,
        onUploadProgress: (progressEvent) => {
          var progress = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100),
          )
          window.loadingUpload(progress)
        },
      })
      if (notif) {
        if (window.$.notify) {
          window.notification(title, message, type)
        }
      }
      return response.data
    } catch (error) {
      if (error.response) {
        var status = error.response.status
        if (status === 444) {
          return refreshToken().then(async (result) => {
            if (result) {
              let res = await putRequest(url, form, notif, title, message, type)
              return res
            } else {
              localStorage.removeItem('expired')
              setLogin(false)
            }
          })
        } else if (status === 445 || status === 403) {
          localStorage.removeItem('expired')
          setLogin(false)
        } else if (status === 409) {
          console.error('Error', error.response)
          Swal.fire('Maaf', error.response.data.message, 'warning')
          return
        } else {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Data', error.response.data)
            console.error('STATUS', error.response.status)
            console.error('HEADER', error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error('REQUEST', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message)
          }
        }

        console.error(error.config)

        if (error.response.data.message === 'username sudah digunakan') {
          return error.response.data
        } else if (error.response.data.message === 'nim sudah digunakan') {
          return error.response.data
        } else if (error.response.data.message === 'password tidak cocok') {
          return error.response.data
        } else if (
          error.response.data.message === 'Kode quiz sudah digunakan'
        ) {
          return error.response.data
        } else if (
          error.response.data.message === 'Code tugas sudah digunakan'
        ) {
          return error.response.data
        } else {
          if (window.$.notify) {
            if (
              error.response.data.message &&
              typeof error.response.data.message === 'string'
            ) {
              window.notification('', error.response.data.message, 'danger')
            } else {
              window.notification(
                'Terjadi kesalahan',
                ', coba beberapa saat lagi',
                'danger',
              )
            }
          }
          return null
        }
      } else {
        if (window.$.notify) {
          window.notification('Koneksi', 'terputus dengan server', 'danger')
        }
      }
      return null
    }
  }

  const deleteRequest = async (
    url,
    notif = false,
    title = 'berhasil',
    message = 'berhasil',
    type = 'success',
  ) => {
    try {
      let token = localStorage.getItem('token')
      let header = {}
      if (token) {
        header.token = token
      }
      const response = await axios({
        method: 'DELETE',
        url: host + url,
        headers: header,
      })
      if (notif) {
        if (window.$.notify) {
          window.notification(title, message, type)
        }
      }
      return response.data
    } catch (error) {
      if (error.response) {
        var status = error.response.status
        if (status === 444) {
          return refreshToken().then(async (result) => {
            if (result) {
              let res = await deleteRequest(url, notif, title, message, type)
              return res
            } else {
              localStorage.removeItem('expired')
              setLogin(false)
            }
          })
        } else if (status === 445 || status === 403) {
          localStorage.removeItem('expired')
          setLogin(false)
        } else if (status === 409) {
          console.error('Error', error.response)
          Swal.fire('Maaf', error.response.data.message, 'warning')
        } else {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Data', error.response.data)
            console.error('STATUS', error.response.status)
            console.error('HEADER', error.response.headers)
            if (window.$.notify) {
              if (
                error.response.data.message &&
                typeof error.response.data.message === 'string'
              ) {
                window.notification('', error.response.data.message, 'danger')
              } else {
                window.notification(
                  'Terjadi kesalahan',
                  ', coba beberapa saat lagi',
                  'danger',
                )
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error('REQUEST', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message)
          }
        }

        console.error(error.config)
      } else {
        if (window.$.notify) {
          window.notification('Koneksi', 'terputus dengan server', 'danger')
        }
      }
      return null
    }
  }

  const downloadRequest = async (url) => {
    let token = localStorage.getItem('token')
    let header = {}
    if (token) {
      header.token = token
    }
    try {
      const response = await axios({
        method: 'GET',
        url: host + url,
        headers: header,
        responseType: 'blob',
      })
      return response.data
    } catch (error) {
      if (error.response) {
        var status = error.response.status
        if (status === 444) {
          return refreshToken().then(async (result) => {
            if (result) {
              let res = await downloadRequest(url)
              return res
            } else {
              localStorage.removeItem('expired')
              setLogin(false)
            }
          })
        } else if (status === 445 || status === 403) {
          localStorage.removeItem('expired')
          setLogin(false)
        } else if (status === 404) {
          if (window.$.notify) {
            window.notification(
              'Maaf,',
              'file yang anda minta tidak ditemukan.',
              'warning',
            )
          }
        } else {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Data', error.response.data)
            console.error('STATUS', error.response.status)
            console.error('HEADER', error.response.headers)
            if (window.$.notify) {
              window.notification(
                error.response.status,
                'Terjadi kesalahan',
                'danger',
              )
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error('REQUEST', error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message)
          }
        }

        console.error(error.config)
      } else {
        if (window.$.notify) {
          window.notification(
            'Error',
            'Koneksi terputus dengan server',
            'error',
          )
        }
      }
      return null
    }
  }

  const refreshToken = async (roleId, profileId, isTriggeredFromChangeProfile) => {
    return new Promise(async (resolve) => {
      let roleActive = JSON.parse(localStorage.getItem('role_active'))
      let profileActive = JSON.parse(localStorage.getItem('profile'))
      let auth = localStorage.getItem('_auth')
      if (auth) {
        var form = new FormData()
        form.append('profile_id', profileId ? profileId : profileActive.id)
        form.append('role_id', roleId ? roleId : roleActive.id)
        form.append('secret', auth)
        let response = await postRequest('auth/v2/signin', form)
        if (response) {
          let tokenDecode = await jwt_decode(response.accessToken)
          localStorage.setItem('_auth', response.secret)
          localStorage.setItem('user', JSON.stringify(tokenDecode.user))
          localStorage.setItem('profile', JSON.stringify(tokenDecode.profile))
          localStorage.setItem('roles', JSON.stringify(response.roles))
          localStorage.setItem(
            'all_profile',
            JSON.stringify(response.allProfile),
          )
          localStorage.setItem(
            'role_active',
            JSON.stringify(response.role_active),
          )
          localStorage.setItem('menu_key', JSON.stringify(tokenDecode.menu_key))
          localStorage.setItem('login_id', JSON.stringify(tokenDecode.login_id))
          localStorage.setItem('token', response.accessToken)
          localStorage.setItem('menus_allow', JSON.stringify(response.menus))
          localStorage.setItem('expired', '1')
          setValue('menus', response.menus)
          setValue('login', true)
          if (!isTriggeredFromChangeProfile) window.location.reload()
          resolve(true)
        }
        localStorage.setItem('msge', '0')
      } else {
        resolve(false)
      }
    })
  }

  const localStorageClear = () => {
    localStorage.clear()
  }

  const postClassActivities = async (contentId, classId) => {
    let form = new FormData()
    form.append('content_id', contentId)
    form.append('class_id', classId)
    const response = await postRequest('class-activities', form)
    if (response) {
      localStorage.setItem('class_activity_id', response.data.id)
    }
  }

  const putClassActivities = async () => {
    if (localStorage.getItem('prev_content_id')) {
      let form = new FormData()
      form.append('content_id', localStorage.getItem('prev_content_id'))
      form.append('class_id', localStorage.getItem('class_id'))
      form.append('activity_id', localStorage.getItem('class_activity_id'))
      if (localStorage.getItem('class_completed') === '1') {
        form.append('is_complete', 1)
      } else {
        form.append('is_complete', 0)
      }
      const response = await putRequest('class-activities', form)
      if (response) {
        localStorage.setItem('class_activity_id', response.data.id)
      }
    }
  }

  const postContentActivities = async (
    contentId,
    classId,
    contentTypeId,
    setActivityId,
  ) => {
    /**
     * content_type_id = {
     *  3 = video
     *  6 = quiz
     * }
     */
    const isContentVideoOrQuiz = [6, 3].includes(contentTypeId)

    let form = new FormData()
    form.append('content_id', contentId)
    form.append('class_id', classId)

    // Check If Feedback Active
    const isFeedbackActive = localStorage.getItem('is_feedback_active')
    if (isFeedbackActive === 'yes') {
      form.append('is_complete', 0)
    } else if (isFeedbackActive === 'no') {
      form.append('is_complete', 0)
    }

    const response = await postRequest('class-content-activities', form)
    if (response) {
      setActivityId(response.data.id)
    }
    localStorage.setItem('activity_id', response.data.id)

    /* ====================================================================================== */
    /*                                      TIMER LOGOUT                                      */
    /* ====================================================================================== */
    /* ==== Request MoM 10/02/2023, 10 Menit Ada Notif, Kalau Tidak Dilanjut Maka Logout ==== */
    /* ================= Kalau Dalam 2 Menit Tidak Ada Response Maka Logout ================= */

    clearTimeout(window.activityTimer)
    clearTimeout(window.logoutTimer)

    const notifyIfTimedOut = () => {
      window.logoutTimeout = setTimeout(() => {
        emitLogout()
      }, 2 * 60 * 1000)
      const user = JSON.parse(localStorage.getItem('user'))
      Swal.fire({
        title: `Hai ${user.name}`,
        text: 'Mengingatkan, \nKamu kan sudah 10 menit di aktivitas ini, \nmasih ingin melanjutkan?',
        showCancelButton: true,
        confirmButtonColor: '#ff5252',
        confirmButtonText: `Tidak, saya mau logout saja`,
        cancelButtonColor: '#345dab',
        cancelButtonText: 'Ya! lanjutkan',
      }).then((result) => {
        result.isConfirmed ? emitLogout() : continueActivity()
      })
    }

    const activityTimeout = () =>
      setTimeout(() => {
        notifyIfTimedOut()
      }, 10 * 60 * 1000)

    const emitLogout = () => {
      setProfile(null)
      clearTimeout(window.activityTimeout)
      localStorage.removeItem('activity_id')
      window.location = '/login?logout'
    }

    const continueActivity = () => {
      clearTimeout(window.activityTimeout)
      clearTimeout(window.logoutTimeout)
      window.activityTimer = activityTimeout()
    }

    if (isContentVideoOrQuiz) { // this mean for content video or quiz is ignore for activityTimeout. Intinya ga di kasih activityTimeout, Request dari Bu Firda (25 Jan 2024)
      window.activityTimer = null
    } else {
      window.activityTimer = activityTimeout()
    }
  }

  const putContentActivities = async (isComplete = false) => {
    const activity_id = localStorage.getItem('activity_id')
    if (localStorage.getItem(`prev_content_id`) && activity_id) {
      let form = new FormData()
      form.append('activity_id', activity_id)
      form.append('content_id', localStorage.getItem(`prev_content_id`))
      form.append('class_id', localStorage.getItem('class_id'))
      if (isComplete === true) {
        form.append('is_complete', 1)
      } else if (isComplete === false) {
        form.append('is_complete', 0)
      }
      await putRequest('class-content-activities', form)
    }
  }

  return (
    <BaseContext.Provider
      value={{
        menus,
        login,
        languageCode,
        profile,
        setProfile,
        getRequest,
        postRequest,
        putRequest,
        deleteRequest,
        setValue,
        localStorageClear,
        downloadRequest,
        postClassActivities,
        putClassActivities,
        postContentActivities,
        putContentActivities,
        changeLanguage,
        getLang,
        refreshToken,
      }}
    >
      {children}
    </BaseContext.Provider>
  )
}

export default BaseContextWrapper