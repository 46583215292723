import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function KelolaShareKelas() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()
  
  /* ======================================= Local States ======================================= */
  const [allCategory, setAllCategory]           = useState([])
  const [categoryId, setCategoryId]             = useState('')
  const [allProfiles, setAllProfiles]           = useState([])
  const [allClassProfiles, setAllClassProfiles] = useState([])
  const [profileId, setProfileId]               = useState('')
  const [sharedClasses, setSharedClasses]       = useState([])
  const [akses, setAkses]                       = useState(2)
  const [isLoading, setIsLoading]               = useState(false)
  
  /* ========================================= Functions ======================================== */
  async function getClassProfiles() {
    const response = await getRequest(
      'classes-profiles?profile_id=' + profileId + '&category_id=' + categoryId,
    )
    if (response) {
      setAllClassProfiles(response.data)
    }
  }

  async function getCategoryActive() {
    const response = await getRequest('categories-active')
    if (response) {
      let temp = response.data.map((item) => ({
        label: item.title,
        value: item.id,
      }))
      setAllCategory(temp)
    }
  }

  async function getProfiles() {
    const response = await getRequest('profile?active=true')
    if (response) {
      let temp = []
      response.data.map((item) => {        
        temp.push({ label: item.name, value: item.id })
      })
      setAllProfiles(temp)
    }
  }

  async function handleSave() {
    setIsLoading(true)
    let form = {
      data: sharedClasses,
    }
    let response = await postRequest('share-classes', form)
    if (response) {
      setIsLoading(false)
      window.notification('Sub Mata Pelajaran', 'berhasil dibagikan', 'success')
      history.goBack()
    }
    setIsLoading(false)
  }

  function handleClick(items) {
    if (profileId) {
      if (akses == 2) {
        window.notification(
          '',
          'Silahkan pilih akses terlebih dahulu',
          'warning',
        )
      } else {
        let profileExist = [].concat(sharedClasses)
        let tempClassId = []
        let checkIndex = sharedClasses.findIndex(
          (item) => item.profile_id == profileId,
        )

        if (profileExist.length == 0 || checkIndex < 0) {
          tempClassId.push(items.id)

          profileExist.push({
            profile_id: profileId,
            class_id: tempClassId,
            status: akses,
          })
        } else {
          tempClassId = profileExist[checkIndex].class_id

          let checkClassExist = tempClassId.findIndex(
            (item) => item == items.id,
          )

          if (checkClassExist >= 0) {
            tempClassId.splice(checkClassExist, 1)
          } else {
            tempClassId.push(items.id)
          }

          profileExist[checkIndex].class_id = tempClassId
        }

        setSharedClasses(profileExist)
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getProfiles()
    getCategoryActive()
  }, [])

  useEffect(() => {
    if(profileId || categoryId){
      getClassProfiles()
    }
  }, [profileId, categoryId])

  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Bagikan Sub Mata Pelajaran" />

        <div className="row">
          <div className="col-sm-12">
            <div className="card user-profile-list">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12">
                    <h5>Kelola Sub Mata Pelajaran</h5>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <button
                      onClick={() => handleSave()}
                      disabled={isLoading}
                      type="button"
                      className="btn btn-success has-ripple float-right"
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                      ) : (
                        <span>Simpan</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <span className="f-w-700 f-14">Profil Sekolah Tujuan</span>
                    <Select
                      className="mt-2"
                      placeholder="Pilih profil sekolah tujuan"
                      isMulti={false}
                      options={allProfiles}
                      onChange={(v) => {
                        setProfileId(v.value)
                      }}
                    />
                  </div>
                  {profileId && (
                    <>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <span className="f-w-700 f-14">
                          Filter Mata Pelajaran
                        </span>
                        <Select
                          isClearable
                          className="mt-2"
                          placeholder="Pilih mata pelajaran"
                          isMulti={false}
                          options={allCategory}
                          onChange={(v) => setCategoryId(v ? v.value : '')}
                        />
                      </div>
                      <div className="col-md-4 mb-3 mb-md-0">
                        <span className="f-w-700 f-14">Akses</span>
                        <Select
                          className="mt-2"
                          placeholder="Pilih akses"
                          isMulti={false}
                          options={[
                            { value: 0, label: 'Hanya akses' },
                            { value: 1, label: 'Duplikat' },
                          ]}
                          onChange={(v) => {
                            setAkses(v.value)
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                {profileId && (
                  <div>
                    {allClassProfiles.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="mt-4"
                        >
                          <h5>
                            {item.title}{' '}
                            <small>- {item.categories_type?.type}</small>
                          </h5>
                          {item.class_profiles.map((items, idx) => {
                            items = items.class
                            let findClass = sharedClasses.find(
                              (v) => v.profile_id == profileId,
                            )

                            let active = false
                            let disabled = false

                            if (items.class_profiles.length > 0) {
                              active = true
                              disabled = true
                            } else {
                              if (findClass)
                                active = findClass.class_id.find(
                                  (v) => v == items.id,
                                )
                            }

                            return (
                              <button
                                disabled={disabled}
                                onClick={() => handleClick(items)}
                                key={idx}
                                className={`btn ${
                                  active
                                    ? 'btn-primary'
                                    : 'btn-outline-secondary'
                                } m-1`}
                              >
                                {items.title}
                              </button>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}
