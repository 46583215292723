import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import moment from 'moment'

import { useBaseContext } from 'context/base'
import { avatarDefaultUser } from 'util/constant'

export default function Header() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { language: ctxLanguage, changeRole, getRequest } = useBaseContext()

  /* =========================================== Refs =========================================== */
  const notifBtnRef = useRef()

  /* ======================================= Local States ======================================= */
  const [user, setUser]                             = useState({})
  const [notification, setNotification]             = useState([])
  const [unreadNotif, setUnreadNotif]               = useState(0)
  const [toggleHeaderMobile, setToggleHeaderMobile] = useState(false)
  const [language, setLanguage]                     = useState('')
  const [windowWidth, setWidth]                     = useState(window.innerWidth)

  /* =================================== Consume Local Storage ================================== */
  const { logo, name } = JSON.parse(localStorage.getItem('profile'))

  /* ========================================= Functions ======================================== */
  function updateDimensions() {
    setWidth(window.innerWidth)
  }

  function getUserRole() {
    const user = localStorage.getItem('user')
    if (user) {
      setUser(JSON.parse(user))
    }
  }

  async function getNotification() {
    const data = await getRequest('notification?read_status=1&limit=15&offset=0')
    if (data) {
      setNotification(data.data)
      setUnreadNotif(data.unreadNotifCount)
    }
  }

  function headerBtnStyles() {
    if (windowWidth >= 995) {
      return {
        color: '#eee',
      }
    } else {
      return {
        color: '#666',
      }
    }
  }

  async function updateNotif() {
    await getNotification()
  }

  function getNotifLinkObject(notif) {
    /*
			notif_code:
				*101 = added siswa to kelas
				*102 = removed siswa from kelas
				*103 = added sub mata pelajaran to kelas
				*104 = removed sub mata pelajaran from kelas
				*200 = invited to video conference
				*300 = new feedback assignment
				*600 = new forum discussion
				*701 = assignment accepted
				*702 = assignment rejected
				*800 = new chats
        *900 = Quiz
        *1000 = Rapor Nilai Submission
        *1001 = Rapor Nilai Reject
        *1100 = Rapor Wali Submission
        *1101 = Rapor Wali Reject
		*/
    let path
    switch (notif.notif_code) {
      case '101':
        if (notif.role_id === 'administrator') path = '/group'
        if (notif.role_id === 'instruktur') path = '/group'
        if (notif.role_id === 'siswa') path = '/siswa-group'
        return path ? path : '/'
      case '102':
        if (notif.role_id === 'administrator') path = '/group'
        if (notif.role_id === 'instruktur') path = '/group'
        if (notif.role_id === 'siswa') path = '/siswa-group'
        return path ? path : '/'
      case '103':
        if (notif.role_id === 'administrator') path = '/group'
        if (notif.role_id === 'instruktur') path = '/group'
        if (notif.role_id === 'siswa') path = '/siswa-group'
        return path ? path : '/'
      case '104':
        if (notif.role_id === 'administrator') path = '/group'
        if (notif.role_id === 'instruktur') path = '/group'
        if (notif.role_id === 'siswa') path = '/siswa-group'
        return path ? path : '/'
      case '200':
        if (notif.role_id === 'administrator') path = '/video-conference'
        if (notif.role_id === 'instruktur') path = '/video-conference'
        if (notif.role_id === 'siswa') path = '/siswa-video-konferensi'
        return path ? path : '/'
      case '300':
        if (notif.role_id === 'siswa')
          path = `/katalog-kelas/${notif.params?.class_id}`
        return path ? path : '/'
      case '600':
        path = notif.params ? `/forum/${notif.params.forum_id}` : '/forum'
        return path ? path : '/'
      case '701':
        if (notif.role_id === 'administrator')
          path = `/kelas/${notif.params?.class_id}`
        if (notif.role_id === 'instruktur')
          path = `/kelas/${notif.params?.class_id}`
        if (notif.role_id === 'siswa')
          path = `/katalog-kelas/${notif.params?.class_id}`
        return path ? path : '/'
      case '702':
        if (notif.role_id === 'administrator')
          path = `/kelas/${notif.params?.class_id}`
        if (notif.role_id === 'instruktur')
          path = `/kelas/${notif.params?.class_id}`
        if (notif.role_id === 'siswa')
          path = `/katalog-kelas/${notif.params?.class_id}`
        return path ? path : '/'
      case '800':
        path = '/chats'
        return path ? path : '/'
      case '900':
        if (notif.role_id === 'administrator')
          path = `/kelas/${notif.params?.class_id}`
        if (notif.role_id === 'instruktur')
          path = `/kelas/${notif.params?.class_id}`
        if (notif.role_id === 'siswa')
          path = `/katalog-kelas/${notif.params?.class_id}`
        return path ? path : '/'
      case '1000':
        path = `/rapor-approval?category_id=${notif.params?.category_id}&semester_id=${notif.params?.ta_semester_code}&group_id=${notif.params?.group_id}`
        return path ? path : '/'
      case '1001':
        path = `/input-rapor?category_id=${notif.params?.category_id}&semester_id=${notif.params?.ta_semester_code}&group_id=${notif.params?.group_id}`
        return path ? path : '/'
      case '1100':
        path = `/preview-rapor/${notif.params?.user_id}/${notif.params?.ta_semester_code}`
        return path ? path : '/'
      case '1101':
        path = `/input-rapor-all/${notif.params?.user_id}/${notif.params?.ta_semester_code}`
        return path ? path : '/'
      default:
        return '/'
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getNotification()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    getUserRole()
  }, [location])

  useEffect(() => {
    if (notifBtnRef) {
      notifBtnRef.current.addEventListener('click', updateNotif)
    }
  }, [notifBtnRef])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      let res = localStorage.getItem('language')
      if (res) {
        let i = res.split('-')
        setLanguage(i[0])
      }
    }

    return () => (isSubscribed = false)
  }, [ctxLanguage])

  async function setAllRead() {
    const response = await getRequest('notification-allread')
    await getNotification()
  }

  async function setReadNotif(id) {
    await getRequest(`notification-read?id=${id}`)
    await getNotification()
  }

  return (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light header-blue">
      <div className="m-header">
        <button
          className="mobile-menu"
          id="mobile-collapse"
          type="button"
          style={{
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
          }}
        >
          <span />
        </button>
        <a
          onClick={() => history.push('/dashboard')}
          className="b-brand"
        >
          {/* ========   change your logo hear   ============ */}
          <img
            src={logo ? `${logo}` : '/logo_sma_current_v3_white_scaled.webp'}
            width="115px"
            alt={name}
            className="logo"
            onError={(e) =>
              (e.currentTarget.src = '/logo_sma_current_v3_white_scaled.webp')
            }
          />
        </a>
        <button
          type="button"
          className="mob-toggler text-light"
          style={{
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
          }}
          onClick={() => {
            if (toggleHeaderMobile) {
              setToggleHeaderMobile(false)
            } else {
              setToggleHeaderMobile(true)
            }
          }}
        >
          <i className="feather icon-more-vertical" />
        </button>
      </div>
      <div
        className={`${
          toggleHeaderMobile ? 'd-flex' : ''
        } collapse navbar-collapse`}
      >
        <ul className="navbar-nav ml-auto">
          <li>
            <div className="dropdown">
              <button
                ref={notifBtnRef}
                className="dropdown-toggle p-0 btn"
                style={{
                  position: 'relative',
                  ...headerBtnStyles(),
                }}
                type="button"
                data-toggle="dropdown"
              >
                <i className="icon feather icon-bell" />
                {unreadNotif > 0 ? (
                  <small
                    className="badge badge-pill badge-danger"
                    style={{
                      position: 'absolute',
                      top: '4px',
                      right: '2px',
                      fontSize: '10px',
                    }}
                  >
                    {unreadNotif}
                  </small>
                ) : null}
              </button>
              <div
                className="dropdown-menu dropdown-menu-right notification shadow"
                style={{ border: 'none' }}
              >
                <div className="noti-head d-flex flex-column">
                  <h6 className="d-inline-block m-b-0">Notifikasi</h6>
                  <div className='d-flex justify-content-between mt-3'>
                    <Link to="/all-notification">Lihat Semua</Link>
                    <Link to="#" onClick={setAllRead}>Tandai Semua Sudah Dibaca</Link>
                  </div>
                </div>
                <ul
                  className="noti-body"
                  style={{
                    height: notification.length === 0 ? 'auto' : '300px',
                    overflowY: 'auto',
                  }}
                >
                  {notification.length > 0 ? (
                    <>
                      {notification.map((notif, index) => {
                        return (
                          <li
                            className="notification p-0"
                            key={index}
                          >
                            <Link
                              to={getNotifLinkObject(notif)}
                              onClick={() =>
                                notif.read === false
                                  ? setReadNotif(notif._id)
                                  : {}
                              }
                            >
                              <div className="media">
                                <div className="media-body px-3">
                                  <p>
                                    <span className="n-time text-muted">
                                      <i className="icon feather icon-clock m-r-5" />
                                      {moment(notif.createdAt).fromNow()}
                                    </span>
                                  </p>
                                  <p
                                    className={`${
                                      notif.read === false ? 'f-w-700' : ''
                                    }`}
                                  >
                                    {notif.notif_message}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                      {unreadNotif && (
                        <li className="notification text-center">
                          <Link to="/all-notification">Lihat Semua</Link>
                        </li>
                      )}
                    </>
                  ) : (
                    <li className="notification text-center">
                      <span className="text-muted">
                        Tidak ada notifikasi baru
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="dropdown drp-user">
              <button
                type="button"
                className="dropdown-toggle btn p-0"
                style={headerBtnStyles()}
                data-toggle="dropdown"
              >
                <i className="feather icon-user" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-right profile-notification shadow"
                style={{ border: 'none' }}
              >
                <div className="pro-head">
                  <img
                    src={
                      Object.keys(user).length !== 0
                        ? `${user.linkAvatar}`
                        : avatarDefaultUser
                    }
                    onError={(event) =>
                      (event.currentTarget.src = avatarDefaultUser)
                    }
                    className="img-radius"
                    style={{ height: '40px', width: '40px' }}
                    alt={user.name ? user.name : 'Avatar'}
                  />
                  <span>{user.name ? user.name : 'User'}</span>
                  <a
                    href="/login?logout"
                    className="dud-logout"
                    title="Logout"
                    onClick={() => setProfile(null)}
                  >
                    <i className="feather icon-log-out" />
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
  )

  async function getNewTokenRole(id) {
    const roleIdActive = JSON.parse(localStorage.getItem('role_active')).id
    if (roleIdActive !== id) {
      const overlayElement = document.createElement('div')
      overlayElement.innerHTML = `
				<div class="notif-overlay">
					<div class="spinner-border text-light" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>
			`
      document.body.append(overlayElement)

      await changeRole(id)
        .then((response) => {
          if (response) {
            document.querySelector('.notif-overlay').remove()
            history.replace('/')
          } else {
            document.querySelector('.notif-overlay').remove()
          }
        })
        .catch((err) => {
          document.querySelector('.notif-overlay').remove()
        })
    }
  }
}
