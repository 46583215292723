import BaseFavicon from 'component/favicon/BaseFavicon.component'
import BaseContextWrapper from 'context/base'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'

export default function BaseRoutes() {
  return (
    <BrowserRouter>
      <BaseContextWrapper>
        <BaseFavicon />
        <Routes />
      </BaseContextWrapper>
    </BrowserRouter>
  )
}
