import { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useBaseContext } from 'context/base'
import useContentPlayerContext from 'context/content-player'

const QuizEvalContext = createContext()

export function QuizEvalWrapper({ quizId, contentId, kelasId, children }) {
  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest } = useBaseContext()
  const { content } = useContentPlayerContext()

  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  /* ============================================================================================ */
  /*                                             SISWA                                            */
  /* ============================================================================================ */
  const [dataSiswa, setDataSiswa] = useState([])

  async function getDataSiswa() {
    const group_id = group?.value === 'all' ? null : group?.value
    if (group?.value !== null) {
      const response = await getRequest(
        `quiz-detail?id=${quizId}&content_id=${contentId}&class_id=${kelasId}&group_id=${group_id}`,
      )
      if (response) {
        setDataSiswa(response.data)
      } else {
        console.error('ERROR', response)
      }
    }
  }

  /* ============================================================================================ */
  /*                                           SUBMAPEL                                           */
  /* ============================================================================================ */
  const [groups, setGroups] = useState([])
  const [group, setGroup] = useState(initSetGroupIfLocalstorageExist)

  async function getGroups() {
    const response = await getRequest(`group-by-mapel?class_id=${kelasId}`)
    if (response) {
      if (response.data.length !== 0) {
        const data = response?.data || []

        const groups = data.map((group) => ({
          value: group.id,
          label: group.group,
        }))
        setGroup({ value: "all", label: "Semua Kelas" })
        setGroups([{ value: "all", label: "Semua Kelas" }, ...groups])
      }
    }
  }

  function onSetGroupHandler(item) {
    localStorage.setItem("periksa-quiz-pilih-group", JSON.stringify({
      ...item,
      content_id: params?.contentId
    }))
    setGroup(item)
  }

  function initSetGroupIfLocalstorageExist() {
    const isSelectedGroupPersistExist = localStorage.getItem("periksa-quiz-pilih-group")
    
    if (isSelectedGroupPersistExist) {
      const selectedGroupPersist = JSON.parse(isSelectedGroupPersistExist)

      if (selectedGroupPersist?.content_id === params?.contentId) {
        // setGroup(selectedGroupPersist)
        return selectedGroupPersist
      } else {
        localStorage.removeItem("periksa-quiz-pilih-group")
        // setGroup({ value: null, label: 'Pilih Kelas'})
        return { value: null, label: 'Pilih Kelas'}
      }
    } else {
      // return setGroup({ value: null, label: 'Pilih Kelas'})
        return { value: null, label: 'Pilih Kelas'}
    }
  }
  /* ============================================================================================ */
  /*                                             QUIZ                                             */
  /* ============================================================================================ */
  const [historyQuiz, setHistoryQuiz] = useState([])
  const [detailQuiz, setDetailQuiz] = useState({})

  async function handleDeleteQuiz(item) {
    try {
      let form = new FormData()
      if (item?.inputValue) {
        form.append('note', item?.inputValue)
      }

      form.append('content_id', item.content_id)
      form.append('user_id', item.id)
      form.append('profile_id', item.profile_id)
      form.append('quiz_id', item.quiz_id)
      form.append('class_id', item.class_id)
      form.append('ta_tahun_code', item.ta_tahun_code)

      const response = await postRequest(`tolak-quiz`, form)

      if (response) {
        Swal.fire({
          icon: 'success',
          title: `Quiz ${item.name} berhasil ditolak`,
        }).then(async () => await getDataSiswa())
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `Quiz ${item.name} gagal ditolak`,
      })
    }
  }

  function handlePreviewAnswerSiswa(dataSiswa) {
    history.push(
      `/kelola-quiz/preview-quiz/${dataSiswa?.quiz_id}/answer-siswa/${dataSiswa.id}`, { existingData: dataSiswa, content: {...location.state.content, ...content} }
    )
  }

  function handleStatus(item) {
    if (item.is_complete == null) {
      return item.history ? (
        <span className={`badge bg-danger text-white`}>Remidial</span>
      ) : (
        <span className={`badge bg-secondary text-white`}>
          Belum mengerjakan
        </span>
      )
    } else {
      if (item.is_complete_nilai > 0) {
        if (item.is_checked > 0) {
          return (
            <>
              <span className={`badge bg-success text-white`}>
                Sudah mengerjakan
              </span>
              <br />
              <span className={`badge bg-warning text-white`}>
                {item.is_checked} Soal belum diperiksa
              </span>
            </>
          )
        } else {
          return (
            <>
              <span className={`badge bg-success text-white`}>
                Sudah diperiksa
              </span>
              <br />
              {item.completed_by && (
                <span className={`badge bg-info text-white`}>
                  Tuntas melalui pathway content oleh: {item.completed_by.user}
                </span>
              )}
            </>
          )
        }
      } else {
        return (
          <span className={`badge bg-secondary text-white`}>
            Belum mengerjakan
          </span>
        )
      }
    }
  }

  function handleNilai(item) {
    if (item.nilai <= 49) {
      return <span className="font-weight-bold text-danger">{item.nilai}</span>
    } else {
      if (item.nilai > 69) {
        return (
          <span className="font-weight-bold text-success">{item.nilai}</span>
        )
      } else {
        return (
          <span className="font-weight-bold text-warning">{item.nilai}</span>
        )
      }
    }
  }

  async function resetQuizHandler(item) {
    Swal.fire({
      title: `Apakah Anda yakin untuk Reset Quiz ${item?.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2778c4',
      cancelButtonColor: '#757575',
      confirmButtonText: 'Ya!',
      cancelButtonText: 'Tidak',
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Sedang Mereset Quiz ${item?.name}...`,
          html: `<div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>`,
          allowOutsideClick: false,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
        })

        const formData = new FormData()

        formData.append('content_id', contentId)
        formData.append('user_id', item.id)
        formData.append('profile_id', item.profile_id)
        formData.append('quiz_id', quizId)
        formData.append('class_id', item.class_id)
        formData.append('ta_tahun_code', item.ta_tahun_code)

        const response = await postRequest(
          'reset-quiz',
          formData,
        )
        if (response) {
          Swal.fire({
            title: `Berhasil Mereset Quiz ${item?.name}`,
            icon: 'success',
            allowOutsideClick: false,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#2778c4',
          }).then(() => {
            getDataSiswa()
          })
        } else {
          console.error({ response })
        }
      }
    });
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getGroups()
  }, [])

  // useEffect(() => {
  //   initSetGroupIfLocalstorageExist()
  // }, [localStorage, params])

  useEffect(() => {
    group && getDataSiswa()
  }, [group])

  const sharedProps = {
    siswa: {
      data: dataSiswa,
      set: setDataSiswa,
      fetch: getDataSiswa,
    },
    quiz: {
      history: {
        data: historyQuiz,
        set: setHistoryQuiz,
      },
      detail: {
        data: detailQuiz,
        set: setDetailQuiz,
      },
      handle: {
        delete: handleDeleteQuiz,
        preview: handlePreviewAnswerSiswa,
        status: handleStatus,
        nilai: handleNilai,
        reset: resetQuizHandler,
      },
    },
    submapel: {
      data: {
        all: groups,
        selected: group,
      },
      handle: {
        set: onSetGroupHandler,
      },
    },
  }

  return (
    <QuizEvalContext.Provider value={sharedProps}>
      {children}
    </QuizEvalContext.Provider>
  )
}

export default function useQuizEval() {
  return useContext(QuizEvalContext)
}
