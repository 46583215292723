import { useEffect, useState } from 'react'
import { useBaseContext } from "context/base"
import { createQueryUrl } from "util/string"
import Swal from 'sweetalert2'
import _ from 'lodash'
import { compareArrayOnlyInLeft, isSameUser } from 'util/array'

/**
 * @typedef {Object} DataTableProps
 * @property {Array<Student>?} data
 * @property {'id' | 'name' | 'linkAvatar' | 'nim'=} comparePropName
 * @property {Array<string>=} column
 * @property {number=} totalRows
 * @property {Array<number>=} limits
 * @property {(search: string) => void=} onChangeSearch
 * @property {(selectedPage: number) => void=} onChangePage
 * @property {(selectedLimit: number) => void=} onChangeLimit
 */

/**
 * Base Modal Selected Student Hook
 * 
 * @param {{
 *  selectedStudents: Array<Student>
 *  originalSelectedStudents: Array<Student>
 *  dataTableProps: DataTableProps
 *  contentId?: string
 *  subMapelId: string
 *  onAddOrRemoveStudent: (item: Student | Array<Student>) => void
 *  onCloseHandler: () => void
 *  onSaveHandler: () => void
 *  isWithCheckSavedOrNot?: boolean
* }} params 
*/
export function useBaseModalSelectedStudentHook(params) {
  const { limits = [10, 20, 30], column = ['NO. INDUK', 'NAMA', 'ACTION'], comparePropName = 'id', data = [] } = params.dataTableProps

  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [search, setSearch] = useState('')
  const [selectedClass, setSelectedClass] = useState('')

  const copySelectedItems = [...params.selectedStudents]

  const { getRequest } = useBaseContext()

  const [listStudentSpesificContent, setListStudentSpesificContent] = useState({
    data: [],
    totalData: 0
  })
  const [optionsClass, setOptionsClass] = useState()

  const isDifferenceSelectedStudents = compareArrayOnlyInLeft(params.selectedStudents, params.originalSelectedStudents, isSameUser);
  const isDifferenceOriginalSelectedStudents = compareArrayOnlyInLeft(params.originalSelectedStudents, params.selectedStudents, isSameUser);
  const isFirstTimeAddedSelectedStudents = params.originalSelectedStudents?.length === 0 && (params.selectedStudents?.length > params.originalSelectedStudents?.length);

  const hasDifferences = !!isDifferenceSelectedStudents?.length || !!isDifferenceOriginalSelectedStudents?.length;
  const isNotYetSaved = params.isWithCheckSavedOrNot && (hasDifferences || isFirstTimeAddedSelectedStudents);

  function close() {
    setSelectedClass('')
    setListStudentSpesificContent({
      data: [],
      totalData: 0
    })
    params.onCloseHandler()
  }

  /**
  * Get List Class By Sub Mapel Id
  * 
  * @param {{
  *   sub_mapel_id
  * }} props
  */
  const getOptionsListClassBySubMapelIdHandler = async(props) => {
    const response = await getRequest(`group-by-mapel?class_id=${props.sub_mapel_id}`)

    if (response) {
      const options = response?.data?.map(d => ({
        label: d?.group,
        value: d?.id
      }))
      setOptionsClass(options)
    }
  }

  /**
  * Get List Student Spesific Content
  * 
  * @param {{
  *  class_id: string,
  *  group_id: string,
  *  content_id?: string,
  *  limit: number,
  *  offset: number,
  *  search: string,
  * }} props 
  * @returns 
  */
  const getListStudentSpesificContentHandler = async(props) => {
    const queryContentId = createQueryUrl({ queryName: 'content_id', value: props?.content_id })
    const querySearch = createQueryUrl({ queryName: 'search', value: props?.search })
    const response = await getRequest(`users-content-specific?class_id=${props.class_id}&group_id=${props?.group_id}&limit=${props.limit}&offset=${props.offset}${queryContentId}${querySearch}`)

    if (response) {
      setListStudentSpesificContent({
        data: response?.data,
        totalData: response?.meta?.totalData
      })
    }
  }

  /**
   * Check Is Added
   * 
   * @param {Student} item
   */
  const checkIsAddedHandler = (item) => {
    return copySelectedItems?.findIndex(selectedStudent => {
      return selectedStudent[comparePropName] === item[comparePropName]
    }) > -1
  }

  /**
   * On Add Student Handler
   * 
   * @param {Student} student 
   */
  const onAddStudentHandler = (student) => {
    params.onAddOrRemoveStudent([...params.selectedStudents, student])
  }

  /**
   * On Remove Student Handler
   * 
   * @param {Student} student 
   */
  const onRemoveStudentHandler = (student) => {
    const filteredData = copySelectedItems?.filter((selectedStudentCopy) => selectedStudentCopy.id !== student.id)
    params.onAddOrRemoveStudent(filteredData)
  }

   /**
   * Check All Student Handler
   */
  const onCheckAllStudentHanlder = () => {
    if(listStudentSpesificContent.data?.length) {
      params.onAddOrRemoveStudent(listStudentSpesificContent?.data)
    }
  }

  /**
   * Clear All Student Handler
   */
  const onClearAllStudentHandler = () => {
    params.onAddOrRemoveStudent([])
  }

  /**
   * Rollback All Student Handler
   */
  const onRollbackStudentHandler = () => {
    if (Array.isArray(params?.originalSelectedStudents) && params?.originalSelectedStudents?.length) {
      params.onAddOrRemoveStudent(params.originalSelectedStudents)
    }
  }

  /**
   * On Close Modal Handler
   */
  const onCloseHandler = () => {
    if (isNotYetSaved) {
      Swal.fire({
        icon: 'warning',
        title: `Perubahan anda belum tersimpan!`,
        text: `Apakah anda yakin untuk tidak menyimpan perubahan Anda?`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ya, yakin!',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (result.isConfirmed) {
          close()
        }
      })
    } else {
      close()
    }
  }

  const onSaveHandler = () => {
    if (params?.isWithCheckSavedOrNot) {
      if (isNotYetSaved) {
        params.onSaveHandler(close)
      } else {
        close()
        Swal.fire({
          icon: 'warning',
          text: `Tidak ada pembaruan spesifik konten siswa yang perlu disimpan!`,
        })
      }
    } else {
      params.onSaveHandler(close)
    }
  }

  useEffect(() => {
    if (params.subMapelId) {
      getOptionsListClassBySubMapelIdHandler({
        sub_mapel_id: params.subMapelId
      })
    }
  }, [params.subMapelId])

  useEffect(() => {
    if (selectedClass?.value) {
      getListStudentSpesificContentHandler({
        content_id: params.contentId,
        class_id: params.subMapelId,
        group_id: selectedClass?.value,
        limit,
        offset,
        search,
      })
    }
  }, [limit, offset, search, selectedClass])

  const usingData = Array.isArray(data) && !!data?.length ? data  : listStudentSpesificContent.data

  return {
    data: {
      limits,
      column,
      optionsClass,
      usingData,
      selectedClass,
      isNotYetSaved,
      totalStudentRemoved: isDifferenceOriginalSelectedStudents?.length,
      totalDataStudentsOfClass: listStudentSpesificContent.totalData
    },
    set: {
      setLimit,
      setOffset,
      setSearch,
      setListStudentSpesificContent,
      setSelectedClass,
    },
    handler: {
      checkIsAddedHandler,
      onAddStudentHandler,
      onRemoveStudentHandler,
      onClearAllStudentHandler,
      onCheckAllStudentHanlder,
      onCloseHandler,
      onSaveHandler,
      onRollbackStudentHandler,
    }
  }
}