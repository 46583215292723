import React, { useState } from "react"
import GraphorSort from "./GraphorSort"
import GraphorGroup from "./GraphorGroup"
import CkeditorReadonly from "component/ckeditor-readonly"
import CkeditorComponentV2 from "component/ckeditor/indexV2"
import MatchedAnswersBtn from "./matched-answers/answer-btn"
import MatchedAnswersModal from "./matched-answers/answer-modal"
import { MatchedAnswerWrapper } from "context/matched-answer"

const Question = ({
  item,
  index,
  handleChangeJawabanBenarEssay,
  handleChangeScore,
}) => {
  return (
    <div
      className="mb-4 px-3 pt-5 pb-3 bg-light border rounded"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <span
        className="bg-primary text-light"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          padding: "5px",
          borderBottomRightRadius: "8.5px",
        }}
      >
        <span className="mr-1 f-w-500">Pertanyaan</span>
        <span className="f-w-500">{item ? item.urutan_pertanyaan : "0"}</span>
      </span>

      {/* MATCHED SECTION */}
      <MatchedAnswerWrapper
        question_id={item.id_pertanyaan}
        answers={item.answers}
        matched={item.quiz_match_answers}
      >
        <MatchedAnswersBtn />
        <MatchedAnswersModal />
      </MatchedAnswerWrapper>

      <div className="d-flex">
        <CkeditorReadonly data={item.pertanyaan} />
      </div>
      <div style={{ marginLeft: "1px" }} className="mb-2 mt-2 row">
        <div className="col-12 col-md-3 col-xl-2 d-flex align-items-center">
          <span style={{ fontWeight: "500" }} className="p-1">
            {item.type_pertanyaan != 2 ? `Jawaban siswa :` : null}
          </span>
        </div>

        <div className="col-12 col-md-9 col-xl-10">
          {item.type_pertanyaan == 0 && (
            <AnswerPG item={item} key={`pg${index}`} />
          )}
          {item.type_pertanyaan == 1 && (
            <AnswerEssay item={item} key={`essay${index}`} />
          )}
          {item.type_pertanyaan == 2 && (
            <AnswerGraphors item={item} key={`graphors${index}`} />
          )}
        </div>
      </div>
      <div style={{ marginLeft: "1px" }} className="mb-3 row">
        <div className="col-12 col-md-3 col-xl-2 d-flex align-items-center">
          <span style={{ fontWeight: "500" }} className="p-1">
            {item.type_pertanyaan != 2 ? `Jawaban benar :` : null}
          </span>
        </div>
        <div className="col-12 col-md-9 col-xl-10">
          {item.type_pertanyaan == 0 && (
            <CorrectPG item={item} key={`cpg${index}`} />
          )}
          {item.type_pertanyaan == 1 && (
            <CorrectEssay item={item} key={`cessay${index}`} />
          )}
          {item.type_pertanyaan == 2 && (
            <CorrectGraphor item={item} key={`cessay${index}`} />
          )}
        </div>
      </div>

      {item.type_pertanyaan == 1 && (
        <InputCorrectEssay
          item={item}
          key={`inputEssay${index}`}
          handleChangeJawabanBenarEssay={handleChangeJawabanBenarEssay}
        />
      )}
      {item.type_pertanyaan == 1 && (
        <InputScoreEssay
          item={item}
          key={`inputScore${index}`}
          handleChangeScore={handleChangeScore}
        />
      )}
      {item.type_pertanyaan == 2 && (
        <InputScoreEssay
          item={item}
          key={`inputScore${index}`}
          handleChangeScore={handleChangeScore}
        />
      )}
      <Pembahasan item={item} index={index} />
    </div>
  )
}

const AnswerPG = ({ item }) => {
  if (item.correct_siswa == 1) {
    return (
      <div className="d-flex align-items-center">
        <i className="fas pt-1 fa-check-circle text-success" /> &nbsp;
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: item.jawaban_pg_siswa }}
        />
      </div>
    )
  } else {
    return (
      <div className="d-flex align-items-center">
        <i className="fas fa-times-circle text-danger" /> &nbsp;
        <div
          className="mt-3"
          dangerouslySetInnerHTML={{ __html: item.jawaban_pg_siswa }}
        />
      </div>
    )
  }
}

const AnswerEssay = ({ item }) => {
  return (
    <>
      {item.jawaban_essay_siswa ? (
        <CkeditorReadonly data={item.jawaban_essay_siswa} />
      ) : (
        <div className="p-1 text-danger">
          <span>siswa tidak mengisi</span>
          <i className="fas fa-times-circle ml-1" />
        </div>
      )}
    </>
  )
}

const AnswerGraphors = ({ item }) => {
  if (item.graphor_type == 1) {
    item["jawaban_essay_siswa"] = item["jawaban_essay_siswa"]
      ? item["jawaban_essay_siswa"]
      : JSON.stringify(["Siswa tidak menjawab"])
    return <GraphorSort item={item} />
  } else if (item.graphor_type == 2) {
    item["jawaban_essay_siswa"] = item["jawaban_essay_siswa"]
      ? item["jawaban_essay_siswa"]
      : JSON.stringify({ list: ["Siswa tidak menjawab"] })
    return <GraphorGroup item={item} />
  } else {
    return null
  }
}

const CorrectPG = ({ item }) => {
  return <CkeditorReadonly data={item.jawaban_benar} />
}

const CorrectEssay = ({ item }) => {
  return <CkeditorReadonly data={item.jawaban_benar} />
}
const CorrectGraphor = ({ item }) => {
  return null
}
const InputCorrectEssay = ({ item, handleChangeJawabanBenarEssay }) => {
  return (
    <div style={{ marginLeft: "1px" }} className="mb-5 row">
      <div className="col-12 col-md-3 col-xl-2">
        <span style={{ fontWeight: "500" }} className="p-1">
          Catatan :
        </span>
      </div>
      <div className="col-12 col-md-9 col-xl-6">
        <CkeditorComponentV2
          data={item?.note || ""}
          onBlur={(data) => {
            handleChangeJawabanBenarEssay({ target: { value: data } }, item)
          }}
          otherConfigs={{
            placeholder: "Berikan catatan untuk siswa (Opsional)",
          }}
        />
      </div>
    </div>
  )
}

const InputScoreEssay = ({ item, handleChangeScore }) => {
  return (
    <div style={{ marginLeft: "1px" }} className="mb-3 row">
      <div className="col-12 col-md-3 col-xl-2">
        <span style={{ fontWeight: "500" }} className="p-1">
          Poin jawaban :
        </span>
      </div>
      <div className="col-12 col-md-3 col-lg-2">
        <div className="input-group input-group-sm mb-2 ml-1">
          <input
            defaultValue={item.score}
            min={0}
            max={item.bobot_nilai}
            onBlur={(event) => handleChangeScore(event, item)}
            type="number"
            className="form-control"
          />
          <div className="input-group-prepend">
            <span className="input-group-text">Poin</span>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-5 mb-3 mb-sm-0 mt-md-1">
        <span className="badge badge-primary">
          Bobot soal = {item.bobot_nilai}
        </span>
      </div>
    </div>
  )
}

const Pembahasan = ({ item, index }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <>
      {item.pembahasan_soal ? (
        <div className="mb-0 mt-3 bg-white">
          <div
            id={`questPembahasan${index}`}
            style={{
              backgroundColor: isHovered ? "#f6f6f6" : "#fff",
            }}
          >
            <h5 className="row m-0 border rounded">
              <a
                type="button"
                className="col row collapsed text-dark m-0 p-3"
                data-toggle="collapse"
                data-target={`#pembahasanQuest${index}`}
                aria-expanded="false"
                aria-controls={`pembahasanQuest${index}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div className="col-10 col-lg-11 pl-0">
                  <span className="f-16" style={{ fontWeight: "500" }}>
                    Pembahasan
                  </span>
                </div>
                <div className="col-2 col-lg-1 pr-0 d-flex justify-content-end">
                  <i className="fas fa-chevron-down" />
                </div>
              </a>
            </h5>
          </div>
          <div
            id={`pembahasanQuest${index}`}
            className="collapse border"
            aria-labelledby={`questPembahasan${index}`}
            data-parent={`#questPembahasan${index}`}
          >
            <CkeditorReadonly data={item.pembahasan_soal} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Question
